<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="clock-stopwatch" clip-path="url(#clip0_2559_23163)">
      <g id="Icon">
        <path d="M8.89005 7.14809V10.111L10.7419 11.2222" fill="#B98D49" />
        <path
          d="M8.89005 16.4073C12.3674 16.4073 15.1863 13.5884 15.1863 10.111C15.1863 6.6337 12.3674 3.81475 8.89005 3.81475C5.4127 3.81475 2.59375 6.6337 2.59375 10.111C2.59375 13.5884 5.4127 16.4073 8.89005 16.4073Z"
          fill="#B98D49"
        />
        <path d="M7.40857 1.59253H10.3715H7.40857Z" fill="#B98D49" />
        <path
          d="M15.0597 4.2533L13.9486 3.14219L14.5041 3.69775"
          fill="#B98D49"
        />
        <path
          d="M2.72043 4.2533L3.83154 3.14219L3.27599 3.69775"
          fill="#B98D49"
        />
        <path
          d="M8.89005 7.14809V10.111L10.7419 11.2222M8.89005 3.81475C5.4127 3.81475 2.59375 6.6337 2.59375 10.111C2.59375 13.5884 5.4127 16.4073 8.89005 16.4073C12.3674 16.4073 15.1863 13.5884 15.1863 10.111C15.1863 6.6337 12.3674 3.81475 8.89005 3.81475ZM8.89005 3.81475V1.59253M7.40857 1.59253H10.3715M15.0597 4.2533L13.9486 3.14219L14.5041 3.69775M2.72043 4.2533L3.83154 3.14219L3.27599 3.69775"
          stroke="#B98D49"
          class="outer-stroke"
          stroke-width="1.48148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g id="Icon_2">
        <path d="M8.88867 7.14819V10.1112L10.7405 11.2223" fill="#B98D49" />
        <path
          d="M8.88867 7.14819V10.1112L10.7405 11.2223"
          stroke="white"
          stroke-width="1.48148"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2559_23163">
        <rect
          width="17.7778"
          height="17.7778"
          fill="white"
          transform="translate(0 0.111084)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<style scoped>
svg {
  fill: var(--primary-color);
}

.outer-stroke {
  stroke: var(--primary-color);
}
</style>
